import React from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import App from "App";
import { Provider } from "react-redux";
import { store } from "app/store";
import { ErrorBoundary } from "features/errorHandling";
import { ColorModeContextProvider } from "features/theme/useColorMode";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ColorModeContextProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </ColorModeContextProvider>
  </React.StrictMode>
);
