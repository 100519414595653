import { Stack, Typography } from "@mui/material";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useConnection } from "features/connections";
import { useGetLoginUrl } from "features/user";
import { Navigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";

interface ValueProp {
  index: number;
  title: string;
  description: string;
}

const VALUE_PROPS = [
  {
    index: 1,
    title: "Sync your email and calendar ",
    description: "Simply get started by signing in your  your google account ",
  },
  {
    index: 2,
    title: "Track back your journey",
    description:
      "Olive will generate lists for every city and top restaurants you’ve visited in the past 5 years",
  },
  {
    index: 3,
    title: "Explore and share",
    description:
      "Explore your personal lists and share your taste with friends",
  },
];

const ValuePropView = ({ index, title, description }: ValueProp) => {
  return (
    <Stack
      direction="row"
      alignItems="start"
      spacing={2}
      sx={{ background: "#F6FFE8", pl: 4, pr: 4, pb: 2, maxWidth: "400px" }}
    >
      <Typography
        sx={{
          textAlign: "center",
          lineHeight: "28px",
          color: "#386A20",
          width: "28px !important",
          height: "28px !important",
          padding: 0,
          borderRadius: "14px",
          border: "1px solid #386A20",
        }}
      >
        {index}
      </Typography>
      <Stack spacing={0.5} sx={{ flex: 1, maxWidth: "400px" }}>
        <Typography
          sx={{ color: "#205107", fontSize: "22px", lineHeight: "28px" }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ color: "#386A20", fontSize: "14px", lineHeight: "20px" }}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default function ValuePropPage() {
  const { data: url, isLoading } = useGetLoginUrl();
  const { session } = useConnection();

  if (session)
    return <Navigate to={`/users/${session.userId}`} replace={true} />;

  if (isLoading) return <LoadingIndicator />;

  const handleGoogleConnect = () => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Stack
      direction="column"
      spacing={5}
      sx={{ background: "#F6FFE8", mt: 7 }}
      alignItems="center"
    >
      <img src="/star.dark.png" width="24px" alt="" />
      <Typography
        variant="h3"
        sx={{ color: "#386A20", fontSize: "32px", fontWeight: "light", pb: 5 }}
      >
        Olive
      </Typography>
      <Stack
        width="100%"
        spacing={4}
        direction="column"
        alignItems="center"
        sx={{ pb: 4 }}
      >
        {VALUE_PROPS.map((valueProp) => (
          <ValuePropView key={valueProp.index} {...valueProp} />
        ))}
      </Stack>
      <GoogleLoginButton
        onClick={handleGoogleConnect}
        text="Connect with Google"
        style={{ marginTop: "0", width: "260px", fontSize: "16px" }}
      />
      {/* <Stack
        sx={{
          background: "#386A20",
          color: "#fff",
          borderRadius: "8px",
          width: "343px",
          height: "44px",
          cursor: "pointer",
        }}
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        onClick={handleGoogleConnect}
      >
        <img src="/google.png" width="20px" alt="" />
        <Typography sx={{ fontSize: "16px" }}>Connect with Google</Typography>
      </Stack> */}
      <Typography
        sx={{
          color: "#042100",
          fontSize: "14px",
          lineHeight: "20px",
          maxWidth: "343px",
          textAlign: "center",
        }}
      >
        By signing in, you agree to Olive’s Terms of Service and{" "}
        <a
          href="https://olive-landing-page.webflow.io/olive-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy.
        </a>
      </Typography>
    </Stack>
  );
}
