import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, Fab, Stack, Typography } from "@mui/material";
import { PlaceList } from "component/place";
import { GooglePlaceAutocomplete } from "component/list/GooglePlaceAutoComplete";
import { useEffect, useMemo, useState } from "react";
import { ShareSheet } from "component/core/ShareSheet";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
import { Helmet } from "react-helmet";
import {
  ListPlace,
  useAddPlaceToList,
  useGetList,
  useUpdatePlaceNote,
  useViewList,
} from "features/list";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useCreatePlace } from "features/place";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useConnection } from "features/connections";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { plural } from "features/language";

export default function ListDetailPage() {
  const navigate = useNavigate();
  const { session } = useConnection();
  const [createPlace] = useCreatePlace();
  const [updatePlaceNote] = useUpdatePlaceNote();
  const [viewList] = useViewList();
  const [addPlaceToList] = useAddPlaceToList();

  const { listId } = useParams();
  const { data: list } = useGetList(listId ?? skipToken);

  const [viewedList, setViewedList] = useState(false);
  const [isShareSheetOpen, setShareSheetOpen] = useState(false);
  const [places, setPlaces] = useState<ListPlace[]>([]);

  const isOwner = useMemo(
    () => session?.userId === list?.userId,
    [session, list]
  );

  const title = useMemo(() => {
    if (!list) {
      return "";
    }

    return `${list.user.firstName} | ${list.name} List`;
  }, [list]);

  useEffect(() => {
    if (list?.places?.length) {
      setPlaces(list.places);

      if (!viewedList) {
        setViewedList(true);
        viewList(list.id);
      }
    }
  }, [list, viewList, viewedList]);

  // Create place
  const handleSelect = async (googlePlaceId: string) => {
    if (!listId) {
      return;
    }

    const { data: place } = await createPlace({ googlePlaceId }).unwrap();
    const listPlace = await addPlaceToList({
      listId,
      placeId: place.id,
    }).unwrap();

    setPlaces((places) => [listPlace, ...places]);
  };

  const handleNoteUpdate = async (placeId: string, note: string) => {
    if (listId) {
      await updatePlaceNote({ listId, placeId, note });
    }
  };

  const handleDelete = async (placeId: string) => {
    setPlaces((places) => places.filter(({ id }) => id !== placeId));
  };

  if (!list) return <LoadingIndicator />;

  const {
    name,
    user: { id: userId, firstName, lastName },
    viewCount,
  } = list;

  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={`Come checkout my ${name} list`}
        />
        <meta
          property="og:image"
          content="https://main.d2k4al3gq1ayn4.amplifyapp.com/logo.png"
        />
      </Helmet>
      <Stack alignItems="start">
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate(`/users/${userId}`);
          }}
        >
          Back
        </Button>
      </Stack>
      <Stack sx={{ cursor: "pointer" }}>
        <Typography sx={{ fontSize: "36px" }}>{name}</Typography>
        <Typography sx={{ fontSize: "16px" }}>
          by {firstName} {lastName}
        </Typography>
      </Stack>

      {viewCount > 0 && (
        <Stack direction="row" spacing={1}>
          <VisibilityIcon />
          <Typography variant="body1">{`${viewCount} ${plural(
            viewCount,
            "view"
          )}`}</Typography>
        </Stack>
      )}
      <Divider />
      <Stack spacing={1} sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: "16px" }}>Add Place</Typography>
        <GooglePlaceAutocomplete onSelect={handleSelect} />
      </Stack>
      <Divider />
      <Box sx={{ mb: 4 }} />
      <PlaceList
        isEditingAllowed={isOwner}
        items={places}
        onNoteUpdate={handleNoteUpdate}
        onDelete={handleDelete}
      />
      {!isShareSheetOpen && (
        <Fab
          color="primary"
          aria-label="share"
          size="large"
          onClick={() => setShareSheetOpen(true)}
          sx={{ position: "fixed", right: 24, bottom: 36 }}
        >
          <ShareIcon />
        </Fab>
      )}
      <ShareSheet
        url={window.location.href}
        isOpen={isShareSheetOpen}
        onDismiss={() => setShareSheetOpen(false)}
      />
    </Stack>
  );
}
