import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToastConfig {
	title?: string;
}

export interface CreateNotificationPayload {
	id: string;
	timestamp: number;
	title: string;
	severity?: NotificationState['severity'];
	toast: ToastConfig | boolean;
	persist: boolean;
}

export interface UpdateNotificationPayload {
	id: string;
	timestamp: number;
	title: string;
	severity?: NotificationState['severity'];
	toast: ToastConfig | boolean;
}

export interface RemoveNotificationPayload {
	id: string;
}

export interface NotificationUpdate {
	timestamp: number;
	title: string;
	severity?: NotificationState['severity'];
}

export interface NotificationState {
	id: string;
	timestamp: number;
	title: string;
	read: boolean;
	severity?: 'success' | 'info' | 'warning' | 'error';
	toast: ToastConfig | boolean;
	persist: boolean;
}

export interface NotificationMenuState {
	open: boolean;
}

export interface ToastState {
	open: boolean;
	active: string | undefined;
	pending: string[];
}

export interface NotificationsState {
	notifications: NotificationState[];
	menu: NotificationMenuState;
	toast: ToastState;
}

export const initialState: NotificationsState = {
	notifications: [],
	menu: {
		open: false
	},
	toast: {
		open: false,
		active: undefined,
		pending: []
	}
}

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,

	reducers: {
		openNotificationMenu(state) {
			state.menu.open = true;
		},
		
		closeNotificationMenu(state) {
			state.menu.open = false;
		},

		createNotification(state, { payload }: PayloadAction<CreateNotificationPayload>) {
			state.notifications.push({
				...payload,
				read: state.menu.open
			})

			if (payload.toast) {
				state.toast.pending.push(payload.id);
			}
		},

		updateNotification(state, { payload }: PayloadAction<UpdateNotificationPayload>) {
			const notification = state.notifications.find(x =>
				x.id === payload.id &&
				x.persist
			)

			if (notification) {
				notification.timestamp = payload.timestamp;
				notification.title = payload.title;
				notification.severity = payload.severity;
				notification.toast = payload.toast;
				notification.read = state.menu.open;

				if (payload.toast) {
					state.toast.pending.push(payload.id);
				}
			}
		},

		removeNotification(state, { payload: { id } }: PayloadAction<RemoveNotificationPayload>) {
			const index = state.notifications.findIndex(x => x.id === id)
			if (index >= 0) {
				state.notifications.splice(index, 1);
			}
		},

		markNotificationsRead(state) {
			state.notifications.filter(x => !x.read).forEach(x => x.read = true);
		},

		displayNextToast(state) {
			if (state.toast.pending.length) {
				state.toast.open = true;
				state.toast.active = state.toast.pending[0];
				state.toast.pending = state.toast.pending.slice(1);
			}
		},

		closeActiveToast(state) {
			state.toast.open = false;
		},

		clearActiveToast(state) {
			state.toast.active = undefined;
		}
	}
});

export const {
	openNotificationMenu,
	closeNotificationMenu,
	createNotification,
	updateNotification,
	removeNotification,
	markNotificationsRead,
	displayNextToast,
	closeActiveToast,
	clearActiveToast
} = notificationsSlice.actions;


export default notificationsSlice.reducer;