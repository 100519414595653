import { requiredNumber, requiredString, requiredUuid } from "app/schema";
import * as yup from "yup";

export const visitSchema = yup.object({
  id: requiredUuid,
  userId: requiredUuid,
  placeId: requiredUuid,
  visitCount: requiredNumber,
  lastVisitedAt: requiredString,
  createdAt: requiredString,
  updatedAt: requiredString,
});
