import { Stack, Typography } from "@mui/material";
import { NewList } from "component/list";
import { useColorMode } from "features/theme";

export const EmptyList = () => {
  const { mode } = useColorMode();

  return (
    <Stack spacing={3} alignItems="center" sx={{ pl: 2, pr: 2 }}>
      <Typography>
        We did not find any places you've visited from your gmail and calendar.
        Let's create your very first list!
      </Typography>
      <img
        src={`/star.${mode === "light" ? "dark" : "light"}.png`}
        width={25}
        alt=""
      />
      <NewList />
    </Stack>
  );
};
