import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { ErrorResponse } from "./api";

export interface QueryResult {
  isLoading: boolean;
  isFetching: boolean;
  fulfilledTimeStamp?: number;
  error?: ErrorResponse | SerializedError;
  refetch: () => void;
}

export interface QueryResultLike extends Partial<QueryResult> {}

export function useCombinedQueryState(
  ...queries: QueryResultLike[]
): QueryResult {
  if (queries.length === 0) {
    throw new Error(
      "The useCombinedQueryState hook must be provided with at least one query."
    );
  }

  const refetch = useCallback(() => {
    queries.forEach((x) => {
      if (typeof x.refetch === "function") x.refetch();
    });
  }, [queries]);

  const fulfilledTimeStamp = useMemo(
    () => queries.map((x) => x.fulfilledTimeStamp).sort()[0],
    [queries]
  );

  return {
    isLoading: queries.some((x) => x.isLoading),
    isFetching: queries.some((x) => x.isFetching),
    fulfilledTimeStamp,
    error: queries.find((x) => x.error)?.error,
    refetch,
  };
}
