import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "app/store.helper";
import moment from "moment";
import {
  TOKEN_PARAMETER,
  USER_ID_PARAMETER,
} from "./consumeTokenFromQueryString";

export interface ConnectionsState {
  session?: Session;
  message?: string;
}

export interface Session {
  created: number;
  userId: string;
  accessToken: string;
  refreshToken: string;
}

export interface SetServerActionPayload {
  origin: string;
  apiUrl?: string;
}

export interface CreateSessionActionPayload {
  created: number;
  userId: string;
  accessToken: string;
  refreshToken: string;
}

export interface RefreshSessionActionPayload {
  accessToken: string;
  accessTokenExpires: number;
}

export interface ClearSessionActionPayload {
  reason?: string;
}

export const initialState: ConnectionsState = {};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,

  reducers: {
    createSession(
      state,
      { payload }: PayloadAction<CreateSessionActionPayload>
    ) {
      state.session = { ...payload };
      console.log("create session", state.session);
      localStorage.setItem(TOKEN_PARAMETER, state.session.refreshToken);
      localStorage.setItem(USER_ID_PARAMETER, state.session.userId);
    },
    refreshSession(
      state,
      { payload }: PayloadAction<RefreshSessionActionPayload>
    ) {
      if (state.session) {
        state.session.accessToken = payload.accessToken;
      }
    },
    clearSession(
      state,
      { payload: { reason } }: PayloadAction<ClearSessionActionPayload>
    ) {
      console.log("clearSession ", reason);
      state.session = undefined;
      localStorage.removeItem(TOKEN_PARAMETER);
      localStorage.removeItem(USER_ID_PARAMETER);
      state.message = reason;
    },
  },
});

export const { clearSession, createSession, refreshSession } =
  connectionsSlice.actions;

export function useConnection() {
  const dispatch = useDispatch();
  const state = useSelector((x) => x.connection);

  return {
    ...state,

    createSession(userId: string, accessToken: string, refreshToken: string) {
      if (!userId || !accessToken) {
        return;
      }

      dispatch(
        createSession({
          created: moment().unix(),
          userId,
          accessToken,
          refreshToken,
        })
      );
    },

    refreshSession(accessToken: string, accessTokenExpires: number) {
      dispatch(
        refreshSession({
          accessToken,
          accessTokenExpires,
        })
      );
    },

    clearSession(reason?: string) {
      dispatch(
        clearSession({
          reason,
        })
      );
    },
  };
}

export default connectionsSlice.reducer;
