import moment from "moment";

export type LogLevel = 'Debug' | 'Information' | 'Warning' | 'Error';
const logLevelValues = ['Debug', 'Information', 'Warning', 'Error'];

export interface LogData {
	loggerName: string;
	extra: Record<string, any> | undefined;
}

export const isValidLogLevel = (level: string | undefined): level is LogLevel => {
	if (!level) {
		return false;
	}

	return logLevelValues.includes(level);
}

export const logWriter = new class LogWriter {
	private loggerLevel: LogLevel = 'Information';

	setLogLevel(level: LogLevel) {
		this.loggerLevel = level;
	}

	write(level: LogLevel, message: string, data: LogData) {
		if (!this.logLevelEnabled(level)) {
			return;
		}

		message = `[${moment().format('HH:mm:ss')}] ${message}`

		switch (level) {
			case 'Debug': console.debug(message, data); break;
			case 'Information': console.info(message, data); break;
			case 'Warning': console.warn(message, data); break;
			case 'Error': console.error(message, data); break;
		}
	}

	private logLevelEnabled(level: LogLevel) {
		switch (this.loggerLevel) {
			case 'Debug': return true;
			case 'Information': return ['Information', 'Warning', 'Error'].includes(level);
			case 'Warning': return ['Warning', 'Error'].includes(level);
			case 'Error': return level === 'Error';
		}
	}
}()