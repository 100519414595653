export const Header = () => {
  // const navigate = useNavigate();
  // const { clearSession } = useConnection();

  // const handleLogout = () => {
  //   clearSession();
  //   navigate("/");
  // };

  return (
    <></>
    // <AppBar
    //   position="fixed"
    //   sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    // >
    //   <Toolbar>
    //     <Stack
    //       direction="row"
    //       justifyContent="space-between"
    //       sx={{ backgroundColor: "primary.main" }}
    //     >
    //       <Typography
    //         variant="h4"
    //         sx={{ color: "#F6FFE8", fontSize: "24px", fontWeight: "light" }}
    //       >
    //         Olive
    //       </Typography>

    //       <Button
    //         variant="text"
    //         sx={{ color: "#F6FFE8", fontSize: "12px" }}
    //         onClick={handleLogout}
    //       >
    //         Log Out
    //       </Button>
    //     </Stack>
    //   </Toolbar>
    // </AppBar>
  );
};
