import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import { useListUsers } from "features/user";
import { LoadingIndicator } from "app/LoadingIndicator";

export default function FriendListsPage() {
  const { data: users, isLoading } = useListUsers();

  if (isLoading) {
    return <LoadingIndicator />;
  }
  console.log("UserDetailPage", users);
  return (
    <Stack>
      <Typography variant="h5">My Friends</Typography>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {users?.map((x) => (
          <ListItem
            key={x.id}
            sx={{
              cursor: "pointer",
              "&:hover": { backgroundColor: "background.active" },
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: "primary.main" }}>
                <img src="/personality.png" alt="" width={25} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={[x.firstName, x.lastName].join(" ")}
              secondary={x.email}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
