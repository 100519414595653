import { SerializedError } from "@reduxjs/toolkit";
import { ErrorResponse } from "./api";

export const isApiErrorResponse = (error: any): error is ErrorResponse => {
  return "status" in error && typeof error.status === "number";
};

const isSerializedError = (error: any): error is SerializedError => {
  return "message" in error && typeof error.message === "string";
};

export const getErrorMessage = (
  error: any,
  fallback: string = "Something went wrong."
): string | undefined =>
  isApiErrorResponse(error)
    ? error.data?.title
    : isSerializedError(error)
    ? error.message
    : fallback;

export type QueryStringPrimitiveValue = string | number | boolean;
export type QueryStringValue =
  | QueryStringPrimitiveValue
  | QueryStringPrimitiveValue[];

export function createQueryString(
  params: Record<string, QueryStringValue>
): string {
  const query = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      query.append(key, value.toString());
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (
          typeof val === "string" ||
          typeof val === "number" ||
          typeof val === "boolean"
        ) {
          query.append(key, val.toString());
        }
      });
    }
  });

  return query.toString();
}
