const esRuleSuffixes = ['s', 'sh', 'ch', 'x', 'z'];

/**
 * Returns a singular or plural version of a word based on the provided count.
 * If no plural form is provided 's' or 'es' will be appended to the singular form based on simple rules.
 */
export const plural = (count: number, singular: string, plural?: string) => {
	if (count === 1) return singular;
	if (plural) return plural;

	if (esRuleSuffixes.some(x => singular.endsWith(x))) {
		return `${singular}es`;
	}

	return `${singular}s`;
}