import { useCallback } from "react";
import { removeBackdrop, requestBackdrop } from "./backdropSlice";
import { useSelector, useDispatch } from "app/store.helper";

export interface BackdropHookResult {
  open: boolean;
  showLoadingOverlay: () => void;
  hideLoadingOverlay: () => void;
}

export const useLoadingOverlay = (): BackdropHookResult => {
  const open = useSelector((x) => x.backdrop.open);
  const dispatch = useDispatch();

  const showLoadingOverlay = useCallback(
    () => dispatch(requestBackdrop()),
    [dispatch]
  );
  const hideLoadingOverlay = useCallback(
    () => dispatch(removeBackdrop()),
    [dispatch]
  );

  return { open, showLoadingOverlay, hideLoadingOverlay };
};
