import { Stack, Typography } from "@mui/material";
import { ListList } from "component/list";
import { useConnection } from "features/connections";
import { List } from "features/list";
import { useColorMode } from "features/theme";
import { User } from "features/user";
import { useMemo } from "react";
import { EmptyList } from "./EmptyList";

interface Props {
  user: User;
  lists: List[];
  cityCount: number;
  placeCount: number;
}

export default function TabPassport({
  user: { id, firstName, lastName },
  lists,
  cityCount,
  placeCount,
}: Props) {
  const { session } = useConnection();
  const { mode } = useColorMode();
  const isPageOwner = useMemo(() => session?.userId === id, [id, session]);

  console.log("isPageOwner", isPageOwner);
  console.log("TabPassport id", id);
  console.log("TabPassport session?.userId", session?.userId);

  const hasCount = cityCount > 0 && placeCount > 0;

  return (
    <Stack>
      {isPageOwner ? (
        <Stack sx={{ m: 2 }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: "36px", color: "text.secondary" }}>
              Hi
            </Typography>
            <Typography sx={{ fontSize: "36px", color: "primary.main" }}>
              {firstName} {lastName}
            </Typography>
          </Stack>
          {hasCount && (
            <>
              <Typography>
                Here is the list of your top cities in the past few years, feel
                free to explore the list.
              </Typography>

              <Stack
                sx={{
                  background: "#205107",
                  p: 3,
                  width: "100%",
                  mt: 2,
                  mb: 4,
                }}
                spacing={1}
              >
                <Typography sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                  In the past 5 years, you’ve visited
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ fontSize: "16px", color: "#B7F397" }}>
                    {cityCount}
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#F6FFE8" }}>
                    cities
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#F6FFE8" }}>
                    |
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#B7F397" }}>
                    {placeCount}
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#F6FFE8" }}>
                    places
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      ) : (
        <Stack sx={{ m: 2 }}>
          <Typography sx={{ fontSize: "36px", color: "primary.main" }}>
            {firstName} {lastName}
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "text.secondary" }}>
            Lists
          </Typography>
        </Stack>
      )}
      {lists.length === 0 ? (
        isPageOwner && <EmptyList />
      ) : (
        <>
          <img
            src={`/star.${mode === "light" ? "dark" : "light"}.png`}
            width={25}
            alt=""
            style={{ margin: "0 auto 46px auto" }}
          />
          <ListList lists={lists} />
        </>
      )}
    </Stack>
  );
}
