import { ApiResponse, appApi } from "app/api";
import {
  dataTransformSchema,
  requiredBoolean,
  requiredString,
  requiredUuid,
  requiredNumber,
  nullableString,
  requiredUuidArray,
  nullableUuid,
} from "app/schema";
import { Place, placeSchema } from "features/place";
import { userSchema } from "features/user";
import { visitSchema } from "features/visit";
import * as yup from "yup";

const listCreateSchema = yup.object({
  name: requiredString,
  placeIds: requiredUuidArray,
});

const listSchema = yup.object({
  userId: requiredUuid,
  name: requiredString,
  placeCount: requiredNumber,
  createdAt: requiredString,
  updatedAt: requiredString,
  places: yup.array().of(placeSchema),
});

const listResourceSchema = listSchema.shape({
  id: requiredUuid,
});

export const listPlaceSchema = yup.object({
  id: requiredUuid,
  listId: requiredUuid,
  placeId: requiredUuid,
  note: nullableString,
  visitId: nullableUuid,
  isHidden: requiredBoolean,
  createdAt: requiredString,
  updatedAt: requiredString,
  visit: visitSchema.nullable(),
  place: placeSchema,
});

const listDetailSchema = listResourceSchema.shape({
  user: userSchema,
  viewCount: requiredNumber,
  places: yup.array().of(listPlaceSchema),
});

const updatePlaceNoteSchema = yup.object({
  listId: requiredUuid,
  placeId: requiredUuid,
  note: requiredString,
});

export function isListPlace(place: Place | ListPlace): place is ListPlace {
  return (place as ListPlace).listId !== undefined;
}

const addPlaceToListRequestschema = yup.object({
  listId: requiredString,
  placeId: requiredString,
});

export interface AddPlaceToListRequest
  extends yup.Asserts<typeof addPlaceToListRequestschema> {}
export interface List extends yup.Asserts<typeof listResourceSchema> {}
export interface ListPlace extends yup.Asserts<typeof listPlaceSchema> {}
export interface ListDetail extends yup.Asserts<typeof listDetailSchema> {}
export interface UpdatePlaceNote
  extends yup.Asserts<typeof updatePlaceNoteSchema> {}
export interface CreateListRequest
  extends yup.Asserts<typeof listCreateSchema> {}

const listApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createList: builder.mutation<ApiResponse<List>, CreateListRequest>({
      query: (body) => ({
        url: "/lists",
        method: "POST",
        body: listCreateSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(listPlaceSchema),
        },
      }),
    }),

    getLists: builder.query<List[], string>({
      query: (userId) => `/lists?userId=${userId}&order=placeCount`,
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(listResourceSchema)),
      },
    }),

    getList: builder.query<ListDetail, string>({
      query: (listId) => `/lists/${listId}`,
      extraOptions: {
        schema: dataTransformSchema(listDetailSchema),
      },
    }),

    viewList: builder.mutation<void, string>({
      query: (listId) => ({
        url: `/lists/${listId}/views`,
        method: "POST",
      }),
    }),

    addPlaceToList: builder.mutation<ListPlace, AddPlaceToListRequest>({
      query: (body) => ({
        url: `/lists/${body.listId}/places`,
        method: "POST",
        body: addPlaceToListRequestschema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(listPlaceSchema),
        },
      }),
    }),

    updatePlaceNote: builder.mutation<ListPlace, UpdatePlaceNote>({
      query: (body) => ({
        url: `/lists/${body.listId}/places/`,
        method: "POST",
        body: updatePlaceNoteSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(listPlaceSchema),
        },
      }),
    }),
  }),
});

export const {
  createList: { useMutation: useCreateList },
  getLists: { useQuery: useGetLists, useLazyQuery: useGetListsLazy },
  getList: { useQuery: useGetList },
  addPlaceToList: { useMutation: useAddPlaceToList },
  updatePlaceNote: { useMutation: useUpdatePlaceNote },
  viewList: { useMutation: useViewList },
} = listApi.endpoints;
