import { Stack, Typography, Divider, Chip } from "@mui/material";
import { PlaceList } from "component/place";
import { PersonalityCard } from "./PersonalityCard";
import { TasteProfile } from "features/taste-profile/taste-profile.api";

interface Props {
  tasteProfile: TasteProfile;
}

export const TabTasteProfile = ({ tasteProfile }: Props) => {
  const { topTags, topPlaces } = tasteProfile;

  return (
    <Stack spacing={4}>
      <PersonalityCard tasteProfile={tasteProfile} />
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" sx={{ pb: 1 }}>
          Your Top Tags
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Everything you love
        </Typography>
        <Stack direction="row" spacing={1}>
          {topTags.map(({ title }) => (
            <Chip
              key={title}
              label={title}
              size="medium"
              sx={{ background: "#0C3900", color: "#FFF" }}
            />
          ))}
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="h5" sx={{ textAlign: "center", pb: 2 }}>
        Your Top Places
      </Typography>
      <PlaceList items={topPlaces} isEditingAllowed={false} />
    </Stack>
  );
};
