import { Stack, Typography } from "@mui/material";
import { ListItem } from "./ListItem";
import { List } from "features/list";

interface Props {
  lists: List[];
}

export const ListList = ({ lists }: Props) => {
  return (
    <Stack direction="column" spacing={3} sx={{ ml: 3, mr: 3 }}>
      {lists.map((list) => (
        <ListItem key={list.id} list={list} />
      ))}
    </Stack>
  );
};
