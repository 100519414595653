import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { GooglePlaceAutocomplete } from "./GooglePlaceAutoComplete";
import { Place, useCreatePlace } from "features/place";
import { useCallback, useState } from "react";
import { PlaceList } from "component/place";
import { useCreateList } from "features/list";
import { useNavigate } from "react-router-dom";
import { useLoadingOverlay } from "features/modals";

export const NewList = () => {
  const [name, setName] = useState("My First List");
  const [places, setPlaces] = useState<Place[]>([]);
  const navigate = useNavigate();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
  const [createPlace] = useCreatePlace();
  const [createList] = useCreateList();

  const handleSelect = useCallback(
    async (googlePlaceId: string) => {
      const { data: place } = await createPlace({ googlePlaceId }).unwrap();
      console.log("place", place);
      setPlaces((places) => [place, ...places]);
    },
    [createPlace]
  );

  const handleDelete = async (placeId: string) => {
    setPlaces((places) => places.filter((p) => p.id !== placeId));
  };

  const handleSubmit = async () => {
    showLoadingOverlay();
    const { data: list } = await createList({
      name,
      placeIds: places.map((x) => x.id),
    }).unwrap();

    hideLoadingOverlay();
    navigate(`/lists/${list.id}`);
  };

  return (
    <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
      <TextField value={name} onChange={(e) => setName(e.target.value)} />
      <Stack spacing={1} sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: "16px" }}>Add Place</Typography>
        <GooglePlaceAutocomplete onSelect={handleSelect} />
      </Stack>
      <Divider />
      <PlaceList
        isEditingAllowed={false}
        items={places}
        onDelete={handleDelete}
      />
      <Button
        variant="contained"
        disabled={places.length === 0}
        onClick={handleSubmit}
      >
        Create
      </Button>
    </Stack>
  );
};
