import { appApi } from "app/api";
import {
  dataTransformSchema,
  nullableString,
  requiredString,
  requiredUuid,
} from "app/schema";
import * as yup from "yup";

export const userSchema = yup.object({
  id: requiredUuid,
  email: requiredString,
  firstName: nullableString,
  lastName: nullableString,
  phoneNumber: nullableString,
  createdAt: requiredString,
  updatedAt: requiredString,
});

export interface User extends yup.Asserts<typeof userSchema> {}

const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => "/users/me",
      extraOptions: {
        schema: dataTransformSchema(userSchema),
      },
    }),
    getUser: builder.query<User, string>({
      query: (userId) => `/users/${userId}`,
      extraOptions: {
        schema: dataTransformSchema(userSchema),
      },
    }),
    listUsers: builder.query<User[], void>({
      query: (userId) => "/users",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(userSchema)),
      },
    }),
    getLoginUrl: builder.query<string, void>({
      query: () => "/users/login",
      extraOptions: {
        schema: dataTransformSchema(requiredString),
      },
    }),
  }),
});

export const {
  getMe: { useQuery: useGetMe },
  getUser: { useQuery: useGetUser },
  listUsers: { useQuery: useListUsers },
  getLoginUrl: { useQuery: useGetLoginUrl },
} = userApi.endpoints;

// class UserService extends ApiService {
//   async getMe() {
//     const {
//       data: { data },
//     } = await this.axiosInstance.get("/users/me");

//     return data as User;
//   }

//   async get(id: string) {
//     const {
//       data: { data },
//     } = await this.axiosInstance.get(`/users/${id}`);

//     return data as User;
//   }

//   getLoginUrl = async () => {
//     const { data } = await this.axiosInstance.get("/users/login");
//     return data.data.data as string;
//   };
// }
