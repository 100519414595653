import { requiredString, requiredUuid } from "app/schema";
import * as yup from "yup";

export const tagSchema = yup.object({
  id: requiredUuid,
  title: requiredString,
  createdAt: requiredString,
  updatedAt: requiredString,
});

export interface Tag extends yup.Asserts<typeof tagSchema> {}
