import React, { useRef, useMemo, useEffect, useState } from "react";
import { Autocomplete, TextField, Typography, Stack } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { debounce } from "@mui/material/utils";

export interface PlaceType {
  description: string;

  place_id: string;

  terms: {
    offset: number;

    value: string;
  }[];

  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface Props {
  onSelect: (googlePlaceId: string) => Promise<void>;
}

const key = "AIzaSyAmybLal7OOFN66r6X6fwYhx9pF5mLnM60";

export const fetchCoordinates = async (placeId: string) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${key}`
  );

  const { results } = await response.json();
  if (results && results.length > 0) {
    return results[0];
  }
};

export const GooglePlaceAutocomplete: React.FC<Props> = ({ onSelect }) => {
  const [options, setOptions] = useState<PlaceType[]>([]);
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState("");

  const loaded = useRef(false);

  useEffect(() => {
    if (value) {
      onSelect(value.place_id);
    }
  }, [value, onSelect]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#__googleMapsScriptId")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places`,
        document.querySelector("head"),
        "__googleMapsScriptId"
      );
    }

    loaded.current = true;
  }

  const fetchData = useMemo(
    () =>
      debounce(
        (
          request: { input: string; types: string[] },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return;
    }

    //           location: new window.google.maps.LatLng(this.latitude, this.longitude),

    fetchData(
      { input: inputValue, types: ["restaurant"] },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          console.log("newoptions", newOptions);
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetchData]);

  return (
    <Autocomplete
      id="google-map-demo"
      onChange={(event: any, newValue: string | PlaceType | null) => {
        if (typeof newValue === "string") {
          console.log("onChange string");

          return;
        }
        console.log("onChange");
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText="No locations"
      sx={{ color: "#000" }}
      style={{ width: "100%", color: "#000" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Stack
              direction="row"
              sx={{
                spacing: 4,
              }}
              alignItems="center"
            >
              <LocationOnIcon color="secondary" />
              <Stack sx={{ ml: 2 }}>
                <Typography variant="body1">
                  {option.structured_formatting.main_text}
                </Typography>
                <Typography variant="caption">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Stack>
            </Stack>
          </li>
        );
      }}
      options={options}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          value={inputValue}
          label="Search Place"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};
