import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useConnection } from "features/connections";
import { useTabNavigation } from "features/navigation";

export const Footer = () => {
  const { tabs, currentTabIndex, setCurrentTabIndex } = useTabNavigation();
  const { session } = useConnection();

  if (!session) {
    return <></>;
  }

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: "block", md: "none" },
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={currentTabIndex}
        onChange={(event, newValue) => {
          setCurrentTabIndex(newValue);
        }}
      >
        {tabs.map((x) => (
          <BottomNavigationAction key={x.title} label={x.title} icon={x.icon} />
        ))}
      </BottomNavigation>
    </Paper>
  );
};
