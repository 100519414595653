import { Typography, Stack, LinearProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useGetUser } from "features/user";
import { useConnection } from "features/connections";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetTasteProfile } from "features/taste-profile/taste-profile.api";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useNavigate } from "react-router-dom";

const VERIFY_ITEMS = [
  {
    image: "/verify/verify_0.png",
    text: "We are syncing your email and calendar ...",
  },
  {
    image: "/verify/verify_1.png",
    text: "We are creating your personalized taste journey ...",
  },
  {
    image: "/verify/verify_2.png",
    text: "We are finalizing your profile...",
  },
];

export default function VerifyPage() {
  const location = useLocation();
  const [verifyItemIndex, setVerifyItemIndex] = useState(-1);
  const navigate = useNavigate();

  const { createSession } = useConnection();

  const [userId, setUserId] = useState();
  const { data: user } = useGetUser(userId ?? skipToken);
  const { data: tasteProfile } = useGetTasteProfile(userId ?? skipToken, {
    pollingInterval: 5000,
  });

  const name = useMemo(
    () => (user ? `${user.firstName} ${user.lastName}` : ""),
    [user]
  );

  useEffect(() => {
    if (user) {
      for (let i = 0; i < VERIFY_ITEMS.length; i++) {
        setTimeout(() => {
          setVerifyItemIndex(i);
        }, 15000 * i);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log("useEFfect");
    const result = {} as { [key: string]: string };
    location.hash
      .slice(1)
      .split("&")
      .forEach((item) => {
        result[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]);
      });

    const accessToken = result["access_token"];
    const idToken = result["id_token"];
    const decoded = jwt_decode(idToken) as any;

    const { sub: userId } = decoded;
    console.log("decoded", decoded);

    createSession(userId, accessToken, accessToken);
    setUserId(userId);
  }, []);

  useEffect(() => {
    if (verifyItemIndex === VERIFY_ITEMS.length - 1) {
      // 5 more seconds, then redirect to home page
      setTimeout(() => {
        if (userId) {
          navigate(`/users/${userId}`);
        }
      }, 5000);
    }
  }, [verifyItemIndex, navigate, userId]);

  if (tasteProfile?.userId)
    return <Navigate to={`/users/${tasteProfile.userId}`} replace={true} />;

  if (verifyItemIndex === -1) {
    return <LoadingIndicator />;
  }

  const { image, text } = VERIFY_ITEMS[verifyItemIndex];
  return (
    <>
      <Stack
        direction="column"
        spacing={3}
        sx={{ background: "#F6FFE8", minHeight: "100vh", p: 5, flex: 1 }}
        alignItems="center"
      >
        <img src="/star.dark.png" width="24px" alt="" />
        <Typography
          variant="h3"
          sx={{
            color: "#386A20",
            fontSize: "32px",
          }}
        >
          Olive
        </Typography>
        <Stack direction="column" spacing={2} alignItems="start">
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "light",
              flex: 1,
              textAlign: "left",
            }}
          >
            <span style={{ color: "#000" }}>Welcome</span>{" "}
            <span style={{ color: "#386A20" }}>{name}</span>
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: "#386A20",
              fontSize: "21px",
              fontWeight: "light",
              textAlign: "left",
              width: "100%",
            }}
          >
            {text}
          </Typography>
        </Stack>

        <img
          src={image}
          style={{ marginTop: "52px" }}
          width="250px"
          height="330px"
          alt=""
        />
      </Stack>
      <LinearProgress sx={{ width: "100%", position: "fixed", top: 0 }} />
    </>
  );
}
