import { logWriter } from './logWriter';

export type ExtraLogData = {};

export const createLogger = (name: string) => ({
	debug(message: string, data?: ExtraLogData) {
		logWriter.write('Debug', message, {
			loggerName: name,
			extra: { ...data }
		})
	},
	info(message: string, data?: ExtraLogData) {
		logWriter.write('Information', message, {
			loggerName: name,
			extra: { ...data }
		})
	},
	warn(message: string, data?: ExtraLogData) {
		logWriter.write('Warning', message, {
			loggerName: name,
			extra: { ...data }
		})
	},
	error(message: string, data?: ExtraLogData) {
		logWriter.write('Error', message, {
			loggerName: name,
			extra: { ...data }
		})
	}
})