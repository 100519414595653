import { createSlice } from '@reduxjs/toolkit';

export interface BackdropState {
	open: boolean;
	count: number;
}

const initialState = {
	open: false,
	count: 0
}

export const backdropSlice = createSlice({
	name: 'backdrop',
	initialState,

	reducers: {
		requestBackdrop(state) {
			state.open = true;
			state.count += 1;
		},

		removeBackdrop(state) {
			if (state.count > 0) {
				state.count--;
			}
			
			if (state.count === 0) {
				state.open = false;
			}
		}
	}
});

export const {
	requestBackdrop,
	removeBackdrop
} = backdropSlice.actions;

export default backdropSlice.reducer;