import { configureStore } from "@reduxjs/toolkit";

import connection from "features/connections/connectionsSlice";
import notifications from "features/notifications/notificationsSlice";
import backdrop from "features/modals/backdropSlice";

import { appApi } from "app/api";

export const store = configureStore({
  reducer: {
    connection,
    backdrop,
    notifications,
    [appApi.reducerPath]: appApi.reducer,
  },

  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(appApi.middleware),
});
