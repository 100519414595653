import { Stack } from "@mui/material";
import { PlaceItem } from "./PlaceItem";
import { Place } from "features/place";
import { ListPlace } from "features/list";

export interface PlaceListProps {
  items: Place[] | ListPlace[];
  isEditingAllowed: boolean;
  onNoteUpdate?: (placeId: string, note: string) => Promise<void>;
  onDelete?: (placeId: string) => Promise<void>;
}

export const PlaceList = ({
  items,
  isEditingAllowed,
  onNoteUpdate,
  onDelete,
}: PlaceListProps) => {
  console.log("items", items);
  return (
    <Stack direction="column" spacing={3} sx={{ pl: 2, pr: 2 }}>
      {items.map((item) => (
        <PlaceItem
          key={item.id}
          item={item}
          isEditingAllowed={isEditingAllowed}
          onNoteUpdate={onNoteUpdate}
          onDelete={onDelete}
        />
      ))}
    </Stack>
  );
};
