import { Snackbar, Stack, Typography } from "@mui/material";
import { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";

interface Props {
  onClick: () => void;
}

const CopyToClipboardButton = ({ onClick }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
    onClick();
  };

  return (
    <>
      <Stack
        onClick={handleClick}
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          cursor: "pointer",
        }}
      >
        <LinkIcon
          sx={{
            backgroundColor: "secondary.main",
            color: "#fff",
            borderRadius: 5,
            p: 1,
          }}
        />
        <Typography variant="body1">Link</Typography>
      </Stack>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;
