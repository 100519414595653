import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { TabPanel } from "component/core";
import { useEffect, useMemo, useState } from "react";
import TabPassport from "./TabPassport";
import { TabTasteProfile } from "component/profile/TabTasteProfile";
import { Helmet } from "react-helmet";
import { useGetUser } from "features/user";
import { List, useGetListsLazy } from "features/list";
import { useGetTasteProfile } from "features/taste-profile/taste-profile.api";
import { useCombinedQueryState } from "app/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useTabNavigation } from "features/navigation";
import FriendListsPage from "features/friend/pages/FriendListsPage";

export default function UserDetailPage() {
  const { userId } = useParams();
  const { data: user, ...userQuery } = useGetUser(userId ?? skipToken);
  const { data: tasteProfile, ...tasteProfileQuery } = useGetTasteProfile(
    userId ?? skipToken
  );

  const [lists, setLists] = useState<List[]>([]);

  const [getLists] = useGetListsLazy();

  const { isLoading } = useCombinedQueryState(userQuery, tasteProfileQuery);
  const { currentTabIndex } = useTabNavigation();

  const title = useMemo(
    () =>
      user?.firstName
        ? `${user.firstName} | Taste Profile`
        : "My Taste Profile",
    [user]
  );

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        const lists = await getLists(userId).unwrap();
        console.log("FIRST ", lists);
        setLists(lists);
      }
    };

    fetch();
  }, [userId, getLists]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Stack spacing={2}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="og:description" content="Come check out my taste profile" />
        <meta
          property="og:image"
          content="https://main.d2k4al3gq1ayn4.amplifyapp.com/logo.png"
        />
      </Helmet>

      <TabPanel value={currentTabIndex} index={0}>
        {user && lists && tasteProfile && (
          <TabPassport
            user={user}
            lists={lists}
            cityCount={tasteProfile.cityCount ?? 0}
            placeCount={tasteProfile.placeCount ?? 0}
          />
        )}
      </TabPanel>

      <TabPanel value={currentTabIndex} index={1}>
        <FriendListsPage />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={2}>
        {tasteProfile && <TabTasteProfile tasteProfile={tasteProfile} />}
      </TabPanel>

      {/* <TabPanel value={currentTabIndex} index={2}>
        {tasteProfile && <PersonalityBreakdown tasteProfile={tasteProfile} />}
      </TabPanel> */}
    </Stack>
  );
}
