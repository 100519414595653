import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ShareSheet } from "component/core/ShareSheet";
import { TasteProfile } from "features/taste-profile/taste-profile.api";

interface Props {
  tasteProfile: TasteProfile;
}

export const PersonalityCard = ({
  tasteProfile: { topPersonality, topPersonalityDescription },
}: Props) => {
  const [isShareSheetOpen, setShareSheetOpen] = useState(false);

  return (
    <Stack
      sx={{
        background: "#205107",
        p: 5,
      }}
      alignItems="center"
      direction={{ xs: "column", md: "row" }}
      spacing={3}
    >
      <Stack alignItems="center" flex={1} spacing={2}>
        <Typography sx={{ color: "#fff", fontSize: "22px" }}>
          Your Taste Personality
        </Typography>
        <img src="/personality.png" width={80} alt="" />
        <Typography sx={{ color: "#B7F397", fontSize: "22px" }}>
          {topPersonality}
        </Typography>
      </Stack>
      <Stack alignItems="center" sx={{ flex: 1 }} spacing={2}>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "16px",
            textAlign: "center",
            pl: 3,
            pr: 3,
          }}
        >
          {topPersonalityDescription}
        </Typography>
        <Typography
          sx={{
            color: "#B7F397",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => setShareSheetOpen(true)}
        >
          Share with Friends
        </Typography>
      </Stack>

      <ShareSheet
        url={window.location.href}
        isOpen={isShareSheetOpen}
        onDismiss={() => setShareSheetOpen(false)}
      />
    </Stack>
  );
};
