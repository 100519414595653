import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { List } from "features/list";

interface Props {
  list: List;
}
export const ListItem = ({ list: { id, name, placeCount } }: Props) => {
  return (
    <Link to={`/lists/${id}`}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="h4" color="text.secondary">
          {name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          ({placeCount})
        </Typography>
        <Box flex={1} />
        <EastIcon sx={{ color: "text.secondary" }} />
      </Stack>
    </Link>
  );
};
