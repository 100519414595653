import { ApiResponse, appApi } from "app/api";
import {
  dataTransformSchema,
  nullableString,
  requiredBoolean,
  requiredNumber,
  requiredString,
  requiredUuid,
} from "app/schema";
import { tagSchema } from "features/tag";
import * as yup from "yup";

export const placeSchema = yup.object({
  id: requiredUuid,
  name: requiredString,
  location: requiredString,
  city: requiredString,
  state: requiredString,
  country: requiredString,
  lat: requiredNumber,
  lng: requiredNumber,
  imageUrl: nullableString,
  isYelpSynced: requiredBoolean,
  price: nullableString,
  yelpUrl: nullableString,
  createdAt: requiredString,
  updatedAt: requiredString,
  tags: yup.array().of(tagSchema).required(),
});

const createPlaceRequestSchema = yup.object({
  googlePlaceId: requiredString,
});

export interface Place extends yup.Asserts<typeof placeSchema> {}
export interface CreatePlaceRequest
  extends yup.Asserts<typeof createPlaceRequestSchema> {}

const placeApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listPlaces: builder.query<Place[], void>({
      query: () => "/places",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(placeSchema)),
      },
    }),
    createPlace: builder.mutation<ApiResponse<Place>, CreatePlaceRequest>({
      query: (body) => ({
        url: "/places",
        method: "POST",
        body: createPlaceRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(placeSchema),
        },
      }),
    }),
  }),
});

export const {
  listPlaces: { useQuery: useListPlaces },
  createPlace: { useMutation: useCreatePlace },
} = placeApi.endpoints;

// class PlaceService {
//   private axiosInstance: AxiosInstance;

//   constructor() {
//     const axiosInstance = axios.create({
//       baseURL: Const.Api.BaseUrl,
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     });

//     axiosInstance.interceptors.request.use(
//       (config) => {
//         const identity = authManager.getIdentity();

//         if (identity) {
//           const { idToken } = identity;
//           (config.headers as any).Authorization = `Bearer ${idToken}`;
//         }

//         console.log("request config", config);
//         return config;
//       },
//       (error) => {
//         // console.log("request error", error);
//         return Promise.reject(error);
//       }
//     );

//     this.axiosInstance = axiosInstance;
//   }

//   async list() {
//     const { data } = await this.axiosInstance.get("/places");
//     return data.data as Place[];
//   }

//   /**
//    * Create an Olive place from Google place DTO
//    * @param dto
//    * @returns
//    */
//   async create(googlePlaceId: string) {
//     const { data } = await this.axiosInstance.post("/places", {
//       googlePlaceId,
//     });
//     return data.data as Place;
//   }
// }
