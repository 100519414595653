import React from 'react';
import { Stack, Typography } from '@mui/material';
import { createLogger } from 'features/logging';

export interface ErrorBoundaryProps {
	children?: React.ReactNode;
}
export interface ErrorBoundaryState {
	hasError: boolean;
}

const log = createLogger('ErrorBoundary');

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false
		};
	}

	static getDerivedStateFromError(error: unknown) {
		return { hasError: true };
	}

	componentDidCatch(error: unknown, errorInfo: unknown) {
		const message =
			error instanceof Error ? error.message :
			typeof error === 'string' ? error :
			'Unknown error type';

		log.error(`An unexpected error occurred: ${message}`);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Stack sx={{
					width: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center'
				}}>
					<Typography variant="h4">Something went wrong</Typography>
					<Typography>Please reload the page and try again.</Typography>
				</Stack>
			)
		}

		return this.props.children; 
	}
}