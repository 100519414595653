import { Stack, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

export default function PrivacyPage() {
  const md = `We at **Olive Inc.**, and our affiliates and subsidiaries (together, " **Olive **", "**we**","**us**", or "**our**") recognize the importance of privacy. 

Privacy laws in United States generally define "personal information"as any information about an identifiable individual, which includes information that can be used on its own or with other information to identify, contact, or locate a single person. Personal information does not include business contact information, including your name, title, or business contact information.

This Privacy Policy (the "**Policy**") applies to information we collect, use, or disclose about you:

- When you use the Olive.com website (the "**Site**") or the Olive mobile application (the "**App**");

- When you use Olive’s merchandise return services (the "**Services**") that are available through our Site, and App and at our drop-off locations. 

- When you use the third-party websites and mobile applications of retailers that use Olive’s Services ("Retailers") which include a link to this Policy. 

- In email, text, and other electronic messages between you and Olive

Olive also acts as a service provider to the Retailers, and may process your information on a Retailer’s behalf, in which case the Retailer’s privacy policy will apply.

This Policy also informs you about your rights and choices with regard to the information collected about you. Your use of our Site, App, and Services, and any dispute over privacy, is subject to this Policy and our Terms of Use, which are available at www.tryolive.xyz, and include applicable limitations on damages and the resolution of disputes.

Please read this policy carefully to understand our policies and practices for collecting, using, disclosing, transferring, and storing your information. If you do not agree with our policies and practices, your choice is not to use ourSite, App or Services. By accessing or using the Site, App or Services, you indicate that you understand, accept, and consent to the practices described in this policy. This policy may change from time to time. Your continued use of the Site, App or Services after we make changes indicates that you accept and consent to those changes, so please check the policy periodically for updates.See the section entitled Changes to This Policy for more information.Information We Collect We may collect information about you directly from you, from Retailers and other third parties, and automatically through your use of our Services. We may combine the information we collect from these various sources to fulfill the purposes outlined in this Policy.

**Information You Provide.**

We collect information that you provide when you return merchandise using our Services (for example, you will need to provide us with your email address and order number for the merchandise your are returning to initiate a return).Please note that Olive’s drop-off locations may be staffed by individuals who are not employed by Olive. To the extent that such individuals have access to any personal information, their access will be limited to the information needed for them to process the returned merchandise at the drop-off location, and they will be subject to a confidentiality agreement with Olive.

**Information from Retailers.**

Where they are authorized to share information about you with us, we collect information about you from the Retailer when you return that Retailers merchandise using our Services (for example, a Retailer will share your email, order details, and shipping address with us so that we can process your return).

**Registration.**

To access certain information or features, we may ask you to register for theSite or App. You can also request or sign up to receive information, updates, news, newsletters and marketing or offers from us. If you register with us, request information or subscribe to our emails, we will collect information from you, such as your name, email address, and other contact information. We may also ask or allow you to provide additional optional information, such as user preferences, age and certain demographics.

Third-Party Accounts.

We may allow you to sign into our Services using third party accounts, such asGoogle or Facebook accounts. When you log in through such third-party accounts, we ask you to grant us permission to access and use certain information from these accounts (as explained on the permissions page), including your name and email address. Our Services may also include social media buttons, such as theFacebook Like button or other similar social media buttons and we may collect information about how individuals like and share content about us on these social media platforms. Please note, any third-party social networking or other site controls the information it collects from you and what information is discloses to third parties. We do not control these third-party websites, and we encourage you to read the privacy policy of every website you visit. 

**Customer Support and Services.**

We collect personal information when you request information from us, contact us for customer service or other purposes. For instance we may email you to answer your inquiry or send you push-notifications on your smartphone unless you choose to disable the push-notification function in your settings.

**Content.**

Our Services may allow Users to submit content to our Services, such as comments or pictures of their returned items. When Users submit such content we maintain a copy of this content. We may also collect certain information metadata associated with your content (including date/time stamp and device type).

**Surveys.**

We collect your personal information you provide to us if you choose to articulate in one of our voluntary surveys.

Rewards and Loyalty Program.

We collect information you provide to us when signing-up and/or participating in our rewards and loyalty program.Your Activities. We collect information about your use of our Services, such as your order history,  including how and to whom return merchandise, and the reasons for those returns.

**Other Information from Third Parties.**

We may also collect information, including personal information about you from third parties, which we may combine with other information we have collected.For example, if you take advantage of a third-party or affiliate offer through the Services, we may collect certain information from that third party about your order or interaction with them, such as purchase amount and details. If you connect or integrate our Services with third party services or devices, you are giving us permission to collect information from these sources.

**Usage and Device Data.**

We automatically collect information about your use of our Services (including our App and Site) through cookies, web beacons, log files and other methods:including log files, IP address, app identifier, device ID, advertising ID, location info, browser type, device type, domain name, the website that led you to our Site, App, or Services, the website to which you go after leaving ourSite, App, or Services, the dates and times you access our Site, App, orServices; language information; device name and model; operating system type, name, and version; the length of time that you are logged into or using ourSite, App, or  Services, and the links you click and your other activities within the Site, App, or Services ("Usage Data"). With your permission, we also may collect the following information from your device: your precise location(geolocation); your camera and/or photos and videos stored within your phone.You can disable our access to your camera, photos, videos, and location services at any time by changing your device’s settings. Please see the"Cookies and Other Tracking Mechanisms" section below for more information.

**‍How We Use Your Information**

‍We may use your information, including personal information, for the following purposes:

Providing and Improving Our Services.

To provide and maintain our Services (for example, we will use your personal information to process a return); to improve our Services; to develop new features, products, or services; to authenticate users; to perform technical operations, such as updating software.

Customer Support and Service.

We use the personal information we collect for customer service and support purposes. For example, if you send us an inquiry we will use your personal information to address and answer your question.

Rewards and Loyalty Program.

To assign, track, issue and post rewards and credits to users accounts; to operate and maintain our customer loyalty program if you participate in such.

Personalizing Content and Ads.

We may use the information we collect about you to personalize the information and content we display to you, including marketing, promotional and sponsored content, as well as providing you with more relevant ads.

Marketing and Communications.

To communicate with you about your account, where relevant, and use of ourServices via email and push notification, including to send you product updates; to respond to your inquiries; subject to your consent, to provide you with news and newsletters, special offers, promotions, promotional contests, and other information we think may interest you. Please see the Your Choices section for more information about how to change your communications preferences.

Research and Analytics.

To monitor, analyze and understand usage and activity trends, to conduct analytics on photos and images you provide, and for other research, analytical, and statistical purposes.

Protecting Rights and Interests.

To protect the safety, rights, property, or security of Olive Inc., theServices, Retailers, any third party service provider, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; and to enforce this Policy or our Terms of Use.

General Business Operations. For the administration of our general business, accounting, record keeping and legal functions, and as part of our routine business administration, such as employee training, compliance auditing and similar internal activities.

Legal Compliance. To comply with applicable legal or regulatory obligations, including as part of a judicial proceeding, to respond to a subpoena, warrant, court order, or other legal process, or as part of a law enforcement or a governmental investigation or request, whether formal or informal.

‍**How We Disclose Your Information**

‍We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.

We may disclose your information, including personal information as follows:

- Users. Any content you post within the Services may be viewable by all other users and Site visitors. This includes comments, reviews, pictures, or other content you choose to share on the Site or App. This information will be publicly available and we cannot prevent such information from being used in a manner that may violate this Policy, our Terms of Use, the law, or your personal privacy.
‍
- Affiliates. We may disclose the information we collect from you to our affiliates or subsidiaries; their use and disclosure of your personal information will be subject to this Policy.

- Business Transfers. We may disclose your information to another entity in connection with an acquisition or merger, sale or transfer of a business unit or assets, bankruptcy proceeding, or as part of any other similar business transfer in which personal information held by Olive Inc. about our customers and users is among the assets transferred, including during negotiations related to such transactions.

- Protecting Rights and Interests. We may disclose your information to protect the safety, rights, property, or security of Olive Inc. and our affiliates, theServices, any third party service provider, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity which Olive Inc., in its sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; and to enforce this Policy or ourTerms of Use.

- Legal Compliance. We may disclose your information to comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; in response to a subpoena, warrant, court order, or other legal process; or aspart of an investigation or request, whether formal or informal, from law enforcement or a government official.

**‍Transferring Your Personal Information**

‍We may transfer personal information that we collect or that you provide as described in this Policy to contractors, service providers, and other third parties we use to support our business (for example, we may transfer your personal information to a third party payment processor so they can issue you are fund after we have processed your return) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process the personal information with the same standards set out in this Policy.

We may process, store, and transfer your personal information in and to theUnited States, which has different privacy laws that may or may not be as comprehensive as Canadian law. In these circumstances, the governments, courts, law enforcement, or regulatory agencies of the United States may be able to obtain access to your personal information under United States law. Whenever we engage a service provider, we require that its privacy and security standard sad here to this policy and applicable Canadian privacy legislation.

**‍Cookies and Other Tracking Mechanisms**

‍We and our service providers use cookies, pixels, java script, and other tracking mechanisms to track information about your use of our Services. We or our service providers may combine this information with other information, including personal information, we collect about you to fulfill the purposes set out in this Policy.

Cookies. Cookies are alphanumeric identifiers that are transferred to your computer’s hard drive through your web browser for record-keeping purposes.Some cookies allow us to make it easier for you to navigate our Site, while others are used to enable a faster log-in process or to allow us to track your activities while using our Site. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.If you disable cookies, certain features may not function.

Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (also referred to as web beacons, web bugs or pixel tags), in connection with our Services to, among other things, track the activities users of our Site, App, and Services, help us manage content, and compile statistics about usage of our Site, App, andServices. We and our service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates and identify when our emails are viewed or forwarded.

Third-Party Analytics. We may use third-party analytics companies, for exampleGoogle Analytics, to evaluate use of our Services. We use these tools to help us understand use of, and to improve, our Services, performance, ad campaigns, and user experiences. These entities may use cookies and other tracking technologies, such as web beacons or local storage objects (LSOs), to perform their services.

Do-Not-Track Signals. Our Site does not respond to do-not-track signals. For more information about do-not-track signals, please visit: www.allaboutdnt.com.You may, however, disable certain tracking as discussed in the Cookies and Other Tracking Mechanisms section above (e.g., by disabling cookies).

**‍Interest-based Ads**

‍We work with third party ad networks, ad exchanges, channel partners, measurement services and others to display more relevant advertising on ourServices, and to manage our advertising on third party sites, mobile apps and online services. In order to display more relevant ads on our behalf and others, these third party ad companies may use cookies, pixels tags, and other tools to collect browsing and activity information on our Services, on third-party sites and across different devices, which may be linked with certain offline data they may also collect IP address, device ID, cookie and advertising IDs, and other identifiers, general location information, and, with your consent, your device’s geolocation information. These third party ad companies may use this information to provide you more relevant ads and content and to evaluate the success of such ads and content. As a result, our ads maybe displayed to you on a search results page or on third party sites. For example, we may work with the following as networks:

- Facebook Ads: for more info and choices, see their privacy policy and ad preferences page on Facebook.

- Google/DoubleClick: for more info and choices, see their privacy policy and ads help page

Custom Audiences and Matching. We may disclose certain information (such as your email address) with third parties (such as Facebook; more info here) - so that we can better target ads and content to our users, and others with similar interests on these third parties’ platforms or networks ("Custom Audiences").The information is used to target our campaigns, not those of others.

As noted above, you may also control how Facebook and other third parties display certain ads to you, as explained further in their respective privacy policies and ad preferences pages. You can opt out of several third party ad servers' and networks' cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of United States and an opt-out tool created by the NetworkAdvertising Initiative. Opting-out of participating ad networks does not opt you out of being served advertising. You may continue to receive generic or "contextual" ads on our Services. You may also continue to receive targeted ads on other websites, from companies that do not participate in the above programs. Many opt-out mechanisms are cookie based. So, if you delete cookies, block cookies or use another device, your opt-out will no longer be effective.

‍Third-Party Links and Widgets

‍Our Site, App and Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy, but instead is governed by the privacy policies of those third-party websites. We are not responsible for the information practices of such third parties. OurSite, App and Services also may include social media features and widgets(collectively "Widgets"), such as a "share this" or "like" button or other interactive mini-programs that run on our Services. Widgets can be used to provide you specific services from other companies (e.g., displaying the news, opinions, music, etc.). Widgets displayed on our Sites are hosted by the relevant third party sites. These third party sites may set cookies to enable the Widgets to function properly and they may collect your IP address browsing history. Their information practices are subject to the privacy policies of the third-party site and not this Policy.

‍Security

‍The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us on secure servers. Any payment transactions performed by Olive will be encrypted using SSL technology.Unfortunately, no data security measures can guarantee 100% security. You should take steps to protect against unauthorized access to your device and account by, among other things, choosing a robust password that nobody else knows or can easily guess and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity. Any transmission of personal information is at your own risk. 

**‍Data Retention**

‍Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize or de-identify your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to you or your consent.

**‍Your Choices**

‍If you ask to hear from us or request information from us, we may send periodic informational and promotional emails to you. You may opt-out of such communications by following the opt-out instructions contained in the email. If you opt out of receiving emails about recommendations or other information we think may interest you, we may still send you emails about your account or anyServices you have requested or received from us.

**‍Children**

‍Our Site, App and Services are not targeted to children under thirteen(13) years of age and we do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on the Site or App or through the Services, or provide any information about yourself to us, including your name, address, telephone number, or email address. If we discover that a child under 13 has provided us with personal information, we will promptly delete such personal information from our systems. If you believe we might have any information from or about a child under 13, please contact us using the contact information provided below.

**‍Accessing and Correcting Your Personal Information**

‍It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. Under Canadian law, you have the right to request access to and to correct the personal information that we hold about you.

You can review and change your personal information by logging into the Site orApp and visiting your account profile page.

If you want to access, review, verify, correct, or withdraw consent to the use of your personal information, please contact us using the contact information provided below. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. We may charge you a fee to access your personal information, however, we will notify you of any fee in advance.

We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.

We will provide access to your personal information, subject to exceptions set out in applicable privacy legislation.If you are concerned about our response or would like to correct the information provided, you may contact our PrivacyOfficer using the contact information provided below. 

**‍Withdrawing Your Consent**

‍Where you have provided your consent to the collection, use, disclosure, and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, if applicable, contact us using the contact information provided below. Please note that if you withdraw your consent we may not be able to provide you with the Services. We will explain the impact to you at the time to help you with your decision.

**‍Changes to this Policy**

‍This Policy is current as of the Last Updated date set forth above. We may change this Policy from time to time, so please check back periodically. We will post any changes to this Policy on our Site, and will take steps to notify you of material changes, such as via email or prominent notice on the Services.You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.

**‍Contact Us**

‍We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices.

Please contact us at:

Privacy Officer

‍1927 West Potomac Ave. Chicago,IL 60622

‍and/orvia email at **oliveprivacypolicy@gmail.com **

We have procedures in place to receive and respond to complaints or inquiries about our handling of personal information, our compliance with this policy, and with applicable privacy laws. To discuss our compliance with this policy please contact our Privacy Officer using the contact information listed above.`;

  return (
    <Stack sx={{ background: "#fff" }} alignItems="center">
      <Stack sx={{ pt: 5, pl: 3, pr: 3, pb: 5, maxWidth: "930px" }}>
        <Typography
          sx={{ fontSize: "38px", lineHeight: "44px", color: "#0c3800", mb: 4 }}
        >
          Olive Privacy Policy
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#333",
          }}
        >
          <ReactMarkdown>{md}</ReactMarkdown>
        </Typography>
      </Stack>
    </Stack>
  );
}
