import { useTokenRefresh } from "features/authentication";
import { useConnection } from "features/connections";
import { useEffect, useRef } from "react";
import {
  consumeTokenFromLocalStorage,
  consumeUserIdFromLocalStorage,
} from "./consumeTokenFromQueryString";

export interface ExistingSessionResult {
  isComplete: boolean;
}

export function useExistingSessionCheck(): ExistingSessionResult {
  const requestSent = useRef(false);
  const [refreshToken, tokenRefreshQuery] = useTokenRefresh();
  const { session, createSession } = useConnection();

  useEffect(() => {
    if (!requestSent.current) {
      requestSent.current = true;

      const token = consumeTokenFromLocalStorage();
      refreshToken({ token });
    }
  }, [refreshToken]);

  useEffect(() => {
    if (!session && tokenRefreshQuery.data) {
      const token = consumeTokenFromLocalStorage();
      const userId = consumeUserIdFromLocalStorage();
      createSession(userId!, tokenRefreshQuery.data.accessToken, token!);
    }
  }, [tokenRefreshQuery.data, session, createSession]);

  return {
    isComplete:
      tokenRefreshQuery.isError ||
      (tokenRefreshQuery.isSuccess && session !== undefined),
  };
}
