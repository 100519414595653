import { Stack, Typography } from "@mui/material";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useConnection } from "features/connections";
import { useGetLoginUrl } from "features/user";
import { Navigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";

const items = [
  {
    title: "Sync Your Account",
    description: "We'll analyze the places you've been to and love",
  },
  {
    title: "Explore Your Lists",
    description:
      "We'll instantly create your lists for every city you've visited for the last five years",
  },
  {
    title: "Share and Compare with Friends",
    description: "Your friends' recommendations right at your fingertips",
  },
];

const quotes = [
  {
    quote:
      "Olive lets me share my travel itineraries so easily and get access to cool experiences my friends have loved so that I don't have to do as much research",
    author: "Paul S.",
  },
  {
    quote:
      "I was planning my trip to Austin, and Olive let me see all the places my friends loved instantly. It made the trip a breeze to plan!",
    author: "Janice P.",
  },
  {
    quote:
      "I loved checking out my 'Taste Profile' on Olive. It's like it totally knows me",
    author: " Laura D.",
  },
];

export default function LandingPage() {
  const { data: url, isLoading } = useGetLoginUrl();
  const { session } = useConnection();

  if (session)
    return <Navigate to={`/users/${session.userId}`} replace={true} />;

  if (isLoading) return <LoadingIndicator />;

  const handleGoogleConnect = () => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        height: "100vh",
        width: "100%",
      }}
    >
      {/* TOP */}
      <Stack
        sx={{
          pt: 15,
          pb: 15,
          background: "#1F5107",
          textAlign: "center",
        }}
        alignItems="center"
      >
        <Stack sx={{ maxWidth: "374px" }} alignItems="center" spacing={3}>
          <Typography sx={{ color: "#f6ffe8" }} variant="h4">
            Olive
          </Typography>
          <Typography sx={{ color: "#f6ffe8" }} variant="body1">
            See your friends' favorite places and share your own travel
            recommendations
          </Typography>
          <GoogleLoginButton
            onClick={handleGoogleConnect}
            text="Connect with Google"
            style={{ marginTop: "16px", width: "216px", fontSize: "16px" }}
          />
          {/* <Stack
            sx={{
              background: "#386A20",
              color: "#fff",
              borderRadius: "8px",
              width: "300px",
              height: "44px",
              cursor: "pointer",
            }}
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            onClick={handleGoogleConnect}
          >
            <img src="/google.png" width="20px" alt="" />
            <Typography sx={{ fontSize: "16px" }}>
              Connect with Google
            </Typography>
          </Stack> */}
        </Stack>
      </Stack>

      {/* MIDDLE */}
      <Stack
        sx={{ background: "#F6FFE8", pt: 12, pb: 12 }}
        alignItems="center"
        spacing={3}
      >
        <Stack alignItems="center" spacing={2}>
          <img src="/star.dark.png" width="28px" alt="" />
          <Typography variant="h6">How it works</Typography>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} spacing={5}>
          {items.map((i, index) => (
            <Stack alignItems="center" spacing={2} sx={{ maxWidth: 260 }}>
              <Typography
                sx={{
                  width: "24px",
                  height: "24px",
                  lineHeight: "24px",
                  textAlign: "center",
                  fontSize: "11px",
                  color: "#1F5107",
                  border: "1px solid #1F5107",
                  borderRadius: "15px",
                }}
              >
                {index + 1}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {i.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              >
                {i.description}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>

      {/* BOTTOM */}
      <Stack
        alignItems="center"
        sx={{
          pt: 9,
          pb: 9,
          background: "#1F5107",
        }}
        spacing={3}
      >
        <Stack alignItems="center" spacing={2}>
          <img src="/star.light.png" width="28px" alt="" />
          <Typography variant="h6" sx={{ color: "#F6FFE8" }}>
            What our customers are Saying
          </Typography>
        </Stack>
        <Stack
          sx={{
            maxWidth: "675px",
            pl: 2,
            pr: 9,
          }}
          spacing={6}
        >
          {quotes.map((q, i) => {
            const align = i % 2 === 0 ? "start" : "end";
            return (
              <Stack
                alignItems={align}
                sx={
                  align === "start"
                    ? {
                        pl: "20px",
                        borderLeft: "5px solid #E2E2E2",
                      }
                    : {
                        pr: "20px",
                        borderRight: "5px solid #E2E2E2",
                      }
                }
                spacing={1}
              >
                <Typography sx={{ color: "#F6FFE8", textAlign: align }}>
                  "{q.quote}"
                </Typography>
                <Typography sx={{ color: "#F6FFE8", textAlign: align }}>
                  -{q.author}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      {/* FOOTER */}
      <Stack
        alignItems="center"
        sx={{
          pt: 9,
          pb: 9,
          background: "#333333",
        }}
        spacing={3}
      >
        <Stack direction="row" spacing={2}>
          <img src="/star.light.png" width="24px" alt="" />
          <Typography variant="h6" sx={{ color: "#FFF" }}>
            Olive
          </Typography>
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{ color: "#fff", textDecoration: "underline" }}
        >
          <a
            href="/privacy"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#fff" }}
          >
            Privacy Policy
          </a>
        </Typography>
      </Stack>
    </Stack>
  );
}
// window.location.href = "/login";
