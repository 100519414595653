export const TOKEN_PARAMETER = "_token";
export const USER_ID_PARAMETER = "_uid";

/** Consumes an authentication token from the query string if present. */
export const consumeTokenFromQueryString = (): string | null => {
  const query = new URLSearchParams(window.location.search);
  const queryToken = query.get(TOKEN_PARAMETER);

  if (queryToken) {
    query.delete(TOKEN_PARAMETER);
    const updatedSearch = query.toString();

    const url = new URL(window.location.href);
    url.search = updatedSearch === "?" ? "" : updatedSearch;

    window.history.replaceState(null, "", url.toString());
  }

  return queryToken;
};

export const consumeTokenFromLocalStorage = () => {
  return localStorage.getItem(TOKEN_PARAMETER);
};

export const consumeUserIdFromLocalStorage = () => {
  return localStorage.getItem(USER_ID_PARAMETER);
};
