import { isPlainObject } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store.helper";
import { clearSession } from "features/connections/connectionsSlice";
import { createLogger } from "features/logging";
import type { AnySchema } from "yup";
import { createQueryString } from "./api.helpers";
import { apiUrl } from "app/configuration";

export interface ApiResponse<T> {
  data: T;
}

export interface ErrorResponse {
  status: number;
  data?: ProblemDetails;
}

export interface ProblemDetails {
  title: string;
}

export interface RequestExtras {
  authenticate?: boolean;
  schema?: AnySchema;
  strictSchema?: boolean;
  map404ToUndefined?: boolean;
}

export type AppBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  ErrorResponse,
  RequestExtras,
  FetchBaseQueryMeta
>;

const logger = createLogger("App API Client");
const baseQuery = fetchBaseQuery({
  paramsSerializer: createQueryString,
});

const appBaseQuery: AppBaseQuery = async (arg, api, extra) => {
  let {
    url,
    headers = new Headers({}),
    ...rest
  } = typeof arg == "string" ? { url: arg } : arg;

  const {
    authenticate = true,
    schema,
    strictSchema = true,
    map404ToUndefined = false,
  } = extra ?? {};

  const fetchConfig: FetchArgs = {
    url,
    signal: api.signal,
    ...rest,
  };

  const {
    connection: { session },
  } = api.getState() as RootState;
  const useAuthentication = session && authenticate;

  fetchConfig.url = new URL(url, apiUrl).href;
  fetchConfig.headers = new Headers(stripUndefined(headers));

  console.log("access token", session?.accessToken);
  if (useAuthentication && session?.accessToken) {
    fetchConfig.headers.set("Authorization", `Bearer ${session.accessToken}`);
  }

  const result = await baseQuery(fetchConfig, api, {});

  if (result.error) {
    const { status } = result.error;

    if (status === 404 && map404ToUndefined) {
      return {
        data: undefined,
        meta: result.meta,
      };
    }

    if ((status === 401 || status === 403) && useAuthentication) {
      logger.warn(
        `The session is being invalidated because status code ${status} was received in response to call to ${url}.`
      );
      api.dispatch(
        clearSession({
          reason: "Something went wrong. Please login again to continue.",
        })
      );
    }

    return {
      error: result.error as ErrorResponse,
      meta: result.meta,
    };
  }

  if (schema) {
    console.log("result.data", result.data);
    return {
      data: schema.cast(result.data, {
        stripUnknown: strictSchema,
      }),
      meta: result.meta,
    };
  }

  return {
    data: result.data,
    meta: result.meta,
  };
};

function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === "undefined") delete copy[k];
  }

  return copy;
}

export const appApi = createApi({
  reducerPath: "api",
  baseQuery: appBaseQuery,
  endpoints: (builder) => ({}),
  tagTypes: ["Campaigns"],
});
