import { useParams } from "react-router-dom";
import { Divider, Stack, Typography } from "@mui/material";
import { ListList } from "component/list";
import { useGetUser } from "features/user";
import { useGetLists } from "features/list";
import { useCombinedQueryState } from "app/api";
import { LoadingIndicator } from "app/LoadingIndicator";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export default function UserListsPage() {
  const { userId } = useParams();
  const { data: user, ...userQuery } = useGetUser(userId ?? skipToken);
  const { data: lists, ...listsQuery } = useGetLists(userId ?? skipToken);

  const { isLoading } = useCombinedQueryState(userQuery, listsQuery);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  console.log("UserDetailPage", lists);
  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Stack direction="row">
        <Typography variant="h5">
          {user?.firstName} {user?.lastName}
        </Typography>
      </Stack>
      <Divider />
      {lists && <ListList lists={lists} />}
    </Stack>
  );
}
