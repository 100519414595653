import moment from "moment";
import * as yup from "yup";

export const requiredString = yup.string().required();
export const requiredNumber = yup.number().required();
export const requiredBoolean = yup.boolean().required();
export const requiredUuid = yup.string().uuid().required();

export const nullableString = yup.string().nullable().defined();
export const nullableNumber = yup.number().nullable().defined();
export const nullableBoolean = yup.boolean().nullable().defined();
export const nullableUuid = yup.string().uuid().nullable().defined();

export const stringArray = yup.array().of(requiredString);
export const numberArray = yup.array().of(requiredNumber);
export const uuidArray = yup.array().of(requiredUuid);

export const requiredStringArray = stringArray.required();
export const requiredNumberArray = numberArray.required();
export const requiredUuidArray = uuidArray.required();

export const nullableStringArray = stringArray.nullable().defined();
export const nullableNumberArray = numberArray.nullable().defined();

// Transformation to de-nest the data attribute
export function dataTransformSchema(schema: yup.Schema) {
  return schema.transform((value) =>
    value.data !== undefined ? value.data : value
  );
}

/** Represents a unix timestamp. */
export const timestamp = yup.number().transform(function (value, original) {
  if (this.isType(value)) return value;
  return moment.utc(original).unix();
});

/** Represents a unix timestamp. Parsed without timezone. */
export const timestampLocal = yup
  .number()
  .transform(function (value, original) {
    if (this.isType(value)) return value;
    return moment(original).unix();
  });

export const requiredTimestamp = timestamp.required();
export const nullableTimestamp = timestamp.nullable().defined();

/**
 * Represents a DateTime object on the server. Transforms from seconds since unix epoch.
 */
export const serverDateTime = requiredString.transform((value) =>
  moment.unix(value).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS")
);

/** Represents a duration in seconds. */
export const durationSeconds = yup
  .number()
  .transform(function (value, original) {
    if (this.isType(value)) return value;
    return moment.duration(original).asSeconds();
  });

export const requiredDurationSeconds = durationSeconds.required();
export const nullableDurationSeconds = durationSeconds.nullable().defined();

/**
 * Represents a TimeSpan object on the server. Transforms from total seconds.
 */
export const serverTimeSpan = requiredString.transform((value) => {
  const duration = moment.duration(value, "seconds");
  const pad = (x: number) => `${x}`.padStart(2, "0");

  let output = `${pad(duration.hours())}:${pad(duration.minutes())}:${pad(
    duration.seconds()
  )}`;

  const days = duration.days();
  if (days) {
    output = `${days}.${output}`;
  }

  return output;
});

export const requiredServerTimeSpan = serverTimeSpan.required();
export const nullableServerTimeSpan = serverTimeSpan.nullable().defined();

export const enumString = <T extends string>(...values: T[]) =>
  yup.mixed<T>().oneOf<T>(values);
