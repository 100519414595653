import { appApi } from "app/api";
import { dataTransformSchema, requiredString, requiredUuid } from "app/schema";
import * as yup from "yup";

export const requestSchema = yup.object({
  email: requiredString,
  password: requiredString,
});

const responseSchema = yup.object({
  userId: requiredUuid,
  accessToken: requiredString,
  idToken: requiredString,
  refreshToken: requiredString,
});

export const refreshTokenResponseSchema = yup.object({
  accessToken: requiredString,
  idToken: requiredString,
});

export interface AuthenticationRequest
  extends yup.Asserts<typeof requestSchema> {}
export interface AuthenticationResult
  extends yup.Asserts<typeof responseSchema> {}
export interface RefreshTokenResult
  extends yup.Asserts<typeof refreshTokenResponseSchema> {}

export interface RefreshTokenRequest {
  token: string | null;
}

export const authenticationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthenticationResult, AuthenticationRequest>({
      query: (request) => ({
        url: "users/login",
        method: "POST",
        body: requestSchema.cast(request),
        // credentials: "include",
      }),
      extraOptions: {
        schema: dataTransformSchema(responseSchema),
      },
    }),
    refreshToken: builder.mutation<
      RefreshTokenResult,
      RefreshTokenRequest | void
    >({
      query: (data) => ({
        url: "users/refresh",
        method: "POST",
        body: {
          refreshToken: data?.token,
        },
        // credentials: "include",
      }),
      extraOptions: {
        authenticate: false,
        schema: dataTransformSchema(refreshTokenResponseSchema),
      },
    }),
    revokeToken: builder.mutation<void, void>({
      query: () => ({
        url: "users/revoke",
        method: "DELETE",
        // credentials: "include",
      }),
    }),
  }),
});

export const {
  login: { useMutation: useLogin },
  refreshToken: { useMutation: useTokenRefresh },
  revokeToken: { useMutation: useTokenRevoke },
} = authenticationApi.endpoints;
