import { LinearProgress, Stack, Typography } from "@mui/material";

interface Props {
  minLabel: string;
  maxLabel: string;
  value: number;
}

export const ProgressBar = ({ minLabel, maxLabel, value }: Props) => {
  return (
    <Stack sx={{ mb: 4 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{minLabel}</Typography>
        <Typography variant="h6">{maxLabel}</Typography>
      </Stack>
      <LinearProgress variant="determinate" value={value} />
      <Typography variant="body1">Your Score: {value}</Typography>
    </Stack>
  );
};
